 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';


Modal.setAppElement('#root');

const apiUrl = process.env.REACT_APP_API_URL;

const imageUrl = 'https://ldf.td360.cl/imagenes/';



function MiCuenta() {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    const [selectedSection, setSelectedSection] = useState('Mis Litros');
    const [balance, setBalance] = useState(0);
    const  [databalance, setDataBalance] = useState({

    });
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [personalData, setPersonalData] = useState({
        nombres: "",
        apellidos: "",
        rut: "",
        cargo: "",
        email: "",
        celular: "",
        direccion: "",
        comuna: "",
        region: "",
        imagen: null
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error("Token not found");
                }

                const decodedToken = jwtDecode(token);
                const userId = decodedToken.user._id;

                // Obtén los datos del usuario del token decodificado
                setPersonalData({
                    nombres: decodedToken.user.nombres,
                    apellidos: decodedToken.user.apellidos,
                    rut: decodedToken.user.rut,
                    cargo: decodedToken.user.cargo,
                    email: decodedToken.user.email,
                    celular: decodedToken.user.celular,
                    direccion: decodedToken.user.direccion,
                    comuna: decodedToken.user.comuna,
                    region: decodedToken.user.region,
                    imagen: decodedToken.user.imagen
                });

                const [balanceRes, historyRes] = await Promise.all([
                    axios.get(`${apiUrl}/points/${userId}/balance`, {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }),
                    axios.get(`${apiUrl}/points/${userId}/history`, {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    })
                ]);

                setBalance(balanceRes.data.pointsAccount.balance);
                setDataBalance(balanceRes.data.pointsAccount);
                setHistory(historyRes.data.history);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 403) {
                    console.error('Authorization failed. Please check if the token is valid and user has access permissions.');
                }
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPersonalData({
            ...personalData,
            [name]: value
        });
        console.log(personalData);
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSave = async () => {
        const requiredFields = [
            'rut', 'nombres', 'apellidos', 'cargo',
            'email', 'celular', 'comuna',
            'direccion', 'region'
        ];

        const missingFields = requiredFields.filter(field => !personalData[field]);

        if (missingFields.length > 0) {
            swal.fire('Error', `Faltan los siguientes campos: ${missingFields.join(', ')}`, 'error');
            return;
        }

        const result = await swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas guardar los cambios?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, guardar',
            cancelButtonText: 'No, cancelar'
        });

        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    swal.fire('Error', 'Token not found', 'error');
                    return;
                }

                const decodedToken = jwtDecode(token);
                const userId = decodedToken.user._id;

                const dataToSend = {
                    rut: personalData.rut,
                    nombres: personalData.nombres,
                    apellidos: personalData.apellidos,
                    cargo: personalData.cargo,
                    perfil: personalData.perfil,
                    email: personalData.email,
                    celular: personalData.celular,
                    comuna: personalData.comuna,
                    direccion: personalData.direccion,
                    region: personalData.region,
                };

                console.log(dataToSend);

                const response = await axios.put(`${apiUrl}/user/edit`, dataToSend, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                setPersonalData(response.data);
                swal.fire('Éxito', 'Datos guardados con éxito', 'success');
            } catch (error) {
                console.error('Error saving data:', error);
                swal.fire('Error', 'Error al guardar los datos', 'error');
            }
        }
    };


    const handleImageUpload = async () => {
        if (!selectedFile) {
            swal.fire('Error', 'Por favor selecciona una imagen', 'error');
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            swal.fire('Error', 'Token not found', 'error');
            return;
        }

        const result = await swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas actualizar tu imagen?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, actualizar',
            cancelButtonText: 'No, cancelar'
        });

        if (result.isConfirmed) {
            try {
                const formData = new FormData();
                formData.append('imagen', selectedFile);

                const response = await axios.put(`${apiUrl}/user/foto`, formData, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                setPersonalData({ ...personalData, imagen: response.data.imagen });
                swal.fire('Éxito', 'Imagen cambiada con éxito', 'success');
            } catch (error) {
                console.error('Error uploading image:', error);
                swal.fire('Error', 'Error al cambiar la imagen', 'error');
            }
        }
    };


    const handlePasswordChange = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            swal.fire('Error', 'Token not found', 'error');
            return;
        }

        const result = await swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas actualizar tu contraseña?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, actualizar',
            cancelButtonText: 'No, cancelar'
        });

        if (result.isConfirmed) {
            try {
                const response = await axios.put(`${apiUrl}/user/pwd`, { password: newPassword }, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                swal.fire('Éxito', 'Contraseña cambiada con éxito', 'success');
                setIsModalOpen(false);
            } catch (error) {
                console.error('Error changing password:', error);
                swal.fire('Error', 'Error al cambiar la contraseña', 'error');
            }
        }
    };


    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const renderContent = () => {
        switch (selectedSection) {
            case 'Mis Litros':
                return (
                    <div className="w-full max-w-2xl">
                        <h2 className="text-red-600 text-lg font-bold mb-4">MIS LITROS </h2>
                        
                        <table className="w-full mb-4 border-collapse">
                            <thead>
                                <tr>
                                    <th className="border-t-2 border-gray-200 px-4 py-2 font-bold text-left text-gray-700">LITROS ACUMULADOS  </th>
                                    <th className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700"></th>
                                    <th className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {history.filter(item => item.type === 'ingreso').map((item, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                        <td className="border px-4 py-2">{item.marca ? item.marca.nombre+' - ' : ''}{item.product ? item.product.name : ''}{item.concurso ? item.concurso : ''}{item.mes ? ' - '+item.mes : ''}</td>
                                        <td className="border px-4 py-2">{new Date(item.date).toLocaleDateString()}</td>
                                        <td className="border px-4 py-2">{item.amount}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="border-t-2 border-gray-200 px-4 py-2 font-bold text-gray-700">TOTAL LITROS ACUMULADOS ANUAL</td>
                                    <th className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700"></th>
                                    <td className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700">{databalance.totalIngresos? databalance.totalIngresos : ''}</td>
                                </tr>
                                <tr>
                                    <td className="border-t-2 border-gray-200 px-4 py-2 font-bold text-gray-700">TOTAL LITROS CANJEADOS</td>
                                    <th className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700"></th>
                                    <td className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700">{history.filter(h => h.type === 'egreso').reduce((total, item) => total + item.amount, 0)}</td>
                                </tr>
                                <tr>
                                    <td className="border-t-2 border-gray-200 px-4 py-2 font-bold text-red-600">TOTAL SALDO</td>
                                    <th className="border-t-2 border-gray-200 px-4 py-2 text-right text-gray-700"></th>
                                    <td className="border-t-2 border-gray-200 px-4 py-2 text-right text-red-600">{balance}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="flex flex-col md:flex-row justify-between items-center mt-4">
                            <div>
                                <p className="text-gray-600">Vigencia de los Litros hasta el <span className="text-red-600">31/12/{currentYear}</span></p>
                                <p className="text-black font-bold">{/*ESTÁS EN EL LUGAR 1 DEL RANKING*/}</p>
                            </div>
                            <div className="flex space-x-2 mt-2 md:mt-0">
                                <button className="border border-red-600 text-red-600 py-2 px-4 rounded hover:bg-red-100" onClick={() => setSelectedSection('Historial de canje')}>
                                    VER MI HISTORIAL DE CANJE
                                </button>
                                <button
                                    className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                                    onClick={() => navigate('/catalogo')}
                                >
                                    CANJEAR LITROS
                                </button>
                            </div>
                        </div>
                    </div>
                );
            case 'Historial de canje':
                return (
                    <div className="w-full max-w-2xl">
                        <h2 className="text-red-600 text-xl font-bold mb-4">HISTORIAL DE CANJE</h2>
                        <table className="w-full mb-4 border-collapse">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border px-4 py-2">NOMBRE</th>
                                    <th className="border px-4 py-2">FECHA</th>
                                    <th className="border px-4 py-2">LITROS CANJEADOS</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {history.filter(item => item.type === 'egreso').map((item, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                        <td className="border px-4 py-2">{item.product ? item.product.name : ''}{item.concurso ? item.concurso : ''}{item.mes ? ' - '+item.mes : ''}</td>
                                        <td className="border px-4 py-2">{new Date(item.date).toLocaleDateString()}</td>
                                        <td className="border px-4 py-2">{item.amount}</td>
                                    </tr>
                                ))}
                                <tr className="bg-gray-100">
                                    <td className="border px-4 py-2 font-bold">TOTAL LITROS CANJEADOS</td>
                                    <td className="border px-4 py-2"></td>
                                    <td className="border px-4 py-2">{history.filter(h => h.type === 'egreso').reduce((total, item) => total + item.amount, 0)}</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2 font-bold text-red-600">TOTAL SALDO</td>
                                    <td className="border px-4 py-2"></td>
                                    <td className="border px-4 py-2 text-red-600">{balance}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="flex justify-end items-center mt-4">
                            <button className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700" onClick={() => navigate('/catalogo')}>
                                CANJEAR LITROS
                            </button>
                        </div>
                    </div>
                );
            case 'Mis datos personales':
                return (
                    <div className="w-full max-w-2xl">
                        <h2 className="text-red-600 text-lg font-bold mb-4">MIS DATOS PERSONALES</h2>
                        <div className="flex flex-col md:flex-row space-x-4 mb-4">
                    
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Nombre</label>
                                    <input
                                        type="text"
                                        name="nombres"
                                        value={personalData.nombres}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Apellidos</label>
                                    <input
                                        type="text"
                                        name="apellidos"
                                        value={personalData.apellidos}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">RUT</label>
                                    <input
                                        type="text"
                                        name="rut"
                                        value={personalData.rut}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Cargo</label>
                                    <input
                                        type="text"
                                        name="cargo"
                                        value={personalData.cargo}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">E-mail</label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={personalData.email}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Celular</label>
                                    <input
                                        type="text"
                                        name="celular"
                                        value={personalData.celular||''}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Dirección</label>
                                    <input
                                        type="text"
                                        name="direccion"
                                        value={personalData.direccion||''}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Comuna</label>
                                    <input
                                        type="text"
                                        name="comuna"
                                        value={personalData.comuna||''}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold text-gray-700">Región</label>
                                    <select
                                        name="region"
                                        value={personalData.region||''}
                                        onChange={handleInputChange}
                                        className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                                    >  <option value="">Seleccione</option>
                                        <option value="Metropolitana">Metropolitana</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button className="border border-red-600 text-red-600 py-2 px-4 rounded hover:bg-red-100" onClick={() => setIsModalOpen(true)}>
                                CAMBIAR CLAVE
                            </button>
                            <button
                                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                                onClick={handleSave}
                            >
                                GUARDAR
                            </button>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col md:flex-row justify-start items-start min-h-screen mt-28">
            <div className="w-full md:w-1/4 max-w-xs bg-black text-white p-6 rounded-md shadow-md ml-0 md:ml-40">
                <div className='pl-5'>
                    <h2 className='text-3xl font-bold relative mt-5 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[30%]'>
                        Mi Cuenta
                    </h2>
                    <br />
                </div>
                <div className="mb-4">
                    <button
                        onClick={() => setSelectedSection('Mis Litros')}
                        className={`block w-full text-left py-2 px-4 mb-2 ${selectedSection === 'Mis Litros' ? 'bg-red-600' : 'bg-black hover:bg-gray-700'} text-white flex items-center`}
                    >
                        <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z" />
                        </svg>
                        Mis Litros
                    </button>
                    <button
                        onClick={() => setSelectedSection('Historial de canje')}
                        className={`block w-full text-left py-2 px-4 mb-2 ${selectedSection === 'Historial de canje' ? 'bg-red-600' : 'bg-black hover:bg-gray-700'} text-white flex items-center`}
                    >
                        <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93C7.06 19.43 4.57 16.94 3.07 14H4v-2H2.07c.48-1.26 1.2-2.41 2.07-3.44l1.46 1.46c.14.14.33.21.53.21s.39-.07.53-.21c.29-.29.29-.77 0-1.06L5.07 7.94C6.17 6.98 7.5 6.22 9 5.7v2.14c-.26.09-.52.21-.77.35-.62.35-1.12.85-1.46 1.46-.13.25-.26.51-.35.77H9v2H5.41c.09.26.21.52.35.77.34.61.84 1.11 1.46 1.46.25.14.51.26.77.35V18.3c-.5-.19-1.02-.38-1.54-.62zM11 13h2v2h-2zm0-4h2v2h-2zm2-3h-2v2h2zm0 10h-2v2h2zm1.54 3.62c-.34.25-.7.49-1.08.71l.84.84c1.02-1.08 1.92-2.24 2.67-3.46l-1.46-1.46c-.29-.29-.77-.29-1.06 0-.14.14-.21.33-.21.53s.07.39.21.53l.93.93c-.42.33-.87.64-1.34.94zm3.67-4.48l.83.83c.07-.02.13-.03.2-.03s.14.01.2.03c-.7-1.34-1.54-2.6-2.51-3.76-.3-.36-.62-.7-.95-1.03l-.93-.93c.33.42.64.87.94 1.34zm-3.68-6.84c.23.23.47.47.71 1.08.17-.44.35-.89.54-1.34.13-.34.27-.68.43-1l-.71-.71c-1.08 1.02-2.24 1.92-3.46 2.67l1.46 1.46c.3-.3.77-.3 1.06 0 .14.14.21.33.21.53s-.07.39-.21.53zm-6.22 6.22c-.14.14-.33.21-.53.21s-.39-.07-.53-.21l-.71-.71c-.44.17-.89.35-1.34.54-.34.13-.68.27-1 .43l.71.71c1.08-1.02 2.24-1.92 3.46-2.67l-1.46-1.46c-.3.3-.3.77 0 1.06zM12 4c1.86 0 3.58.64 4.95 1.7-.23-.33-.47-.63-.71-.9-1.42-1.55-3.21-2.8-5.24-3.57C11.76 3.02 11.88 3 12 3zm-3.95 2.12C9.1 6.38 10.44 6.7 12 7.29v-2.3c-2.03.77-3.82 2.02-5.24 3.57-.23.27-.47.57-.71.9zM6 12c0 1.65.36 3.2 1.01 4.59-.47-.82-.91-1.69-1.31-2.58C5.38 13.39 5.05 12.7 5.76 12c.38-.38.94-.59 1.54-.62V10H4v2h2zm2.95 6.88c1.37-1.06 3.09-1.7 4.95-1.7s3.58.64 4.95 1.7c-.23-.33-.47-.63-.71-.9-1.42-1.55-3.21-2.8-5.24-3.57v2.3c1.56.59 2.9.91 4.95 2.35.3.25.58.53.86.83-.3-.23-.57-.48-.85-.71-1.42-1.55-3.21-2.8-5.24-3.57V20c1.56-.59 2.9-.91 4.95-2.35.3-.25.58-.53.86-.83-.3.23-.57.48-.85.71-1.42 1.55-3.21 2.8-5.24 3.57-.3.23-.57.48-.85.71.3-.23.57-.48.85-.71 1.42-1.55 3.21-2.8 5.24-3.57V12h-2c0 2.76-1.42 5.26-3.54 6.88zM14 12c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2z" />
                        </svg>
                        Historial de canje
                    </button>
                    <button
                        onClick={() => setSelectedSection('Mis datos personales')}
                        className={`block w-full text-left py-2 px-4 mb-2 ${selectedSection === 'Mis datos personales' ? 'bg-red-600' : 'bg-black hover:bg-gray-700'} text-white flex items-center`}
                    >
                        <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-4-9h8v2H8zm0 4h8v2H8zm0-8h8v2H8z" />
                        </svg>
                        Mis datos personales
                    </button>
                </div>
                <button className="block w-full text-left py-2 px-4 bg-black hover:bg-gray-700 text-white flex items-center"
                    onClick={handleLogout}
                >
                    <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M10 17v-2h4v2h5v-2c0-1.1-.9-2-2-2H12V9h5c1.1 0 2-.9 2-2V7h-5v2h-4V7H7v2c1.1 0 2 .9 2 2v2H7c-1.1 0-2 .9-2 2v2h5z" />
                    </svg>
                    Cerrar sesión
                </button>
            </div>
            <div className="w-full md:w-3/4 p-6 bg-white rounded-md shadow-md">
                {renderContent()}
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Cambiar Clave"
                className="fixed inset-0 flex items-center justify-center"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    <h2 className="text-lg font-bold mb-4">Cambiar Clave</h2>
                    <div className="mb-4">
                        <label className="block text-sm font-bold text-gray-700">Nueva Contraseña</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="border border-gray-600 text-gray-600 py-2 px-4 rounded hover:bg-gray-100"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handlePasswordChange}
                            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default MiCuenta;
